import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app.jsx';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { rootStore } from "./mobx/store";

// css
import './scss/font.scss';
import './scss/main.scss';
// ========================================
// extension
import './helper/extension';
import 'dayjs/locale/ko';
// ========================================

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider rootStore={rootStore}>
      <App />
    </Provider>
  </BrowserRouter>
);
