export default {
  app_name: '체크인',
  qr_scan: {
    title: 'QR코드 스캔',
    desc: 'QR코드를 스캔하세요',
  },
  notice: {
    title: '안내사항',
    desc: '일본 법률에서는\n숙박자의 본인 확인과 외국인 투숙객의 경우에는\n여권 정보를 저장하는 것이 의무화되어 있습니다.',
    check_in: '체크인'
  },
  login: {
    how_many_guests: '숙박자는 몇명입니까?',
    passport_photo: '여권 촬영',
    take_passport_photo_desc: '1명의 여권을 카메라로 촬영하여 주십시오',
    enter_guest_info: '숙박자 정보 입력',
    enter_guest_info_desc: '1명의 숙박자의 정보를 입력하여 주십시오.',
    name: '이름',
    job: '직업',
    address: '주소',
    phone: '전화번호',
    face_shot: '얼굴 촬영',
    face_shot_desc: '1명의 얼굴 사진을 카메라로 촬영합니다.\n준비되셨다면 촬영 버튼을 눌러 주십시오.'
  },
  checkin_complete: {
    title: '체크인 완료',
    desc: '체크인 처리가 종료되었습니다.\n마음 편히 지내시기 바랍니다.'
  },
  guide: {
    title: '안내사항',
    check_out: '체크아웃'
  },
  popup: {
    enter_guest_info: '{0}명의 숙박자의 정보를 입력하여 주십시오.\n일본에 거주하시나요?',
    check_out_desc: '체크아웃 하시겠습니까?\n두고 오신 물건은 없으신가요?\n문단속을 부탁드립니다.',
  },
  alert: {
    enter_guest_count: '숙박자수를 입력하세요.',
    take_passport_photo: '여권사진을 촬영하세요.',
    enter_guest_info: '숙박자정보를 입력하세요.',
    take_face_photo: '얼굴을 촬영하세요.',
    face_not_match: '여권사진과 본인얼굴이 동일하지 않습니다.'
  },
  common: {
    back: '뒤로',
    next: '다음',
    cancel: '취소',
    confirm: '확인',
    no: '아니요',
    yes: '네',
  }
}