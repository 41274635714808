import React from "react";
import '../../scss/components/modal.scss';

const Modal = React.memo((props) => {

  const {
    show = false,
    title = '',
    text = '',
    okBtn = '',
    cancelBtn = '',
    onModalClose
  } = props;

  const onClose = res => {
    onModalClose?.(res || false);
  };

  return (
    <div className={'modal' + (show ? ' active' : '')} onClick={onClose}>
      <div className='content' onClick={e => e.stopPropagation()}>
        <div className='body'>
          {
            title != '' && <label className='title'>{title.nl2br()}</label>
          }
          {
            text != '' && <label className='text'>{text.nl2br()}</label>
          }
        </div>
        <div className='footer'>
          {
            cancelBtn != '' && <button onClick={() => onClose(false)}>{cancelBtn}</button>
          }
          {
            okBtn != '' && <button onClick={() => onClose(true)}>{okBtn}</button>
          }
        </div>
      </div>
    </div>
  );
});

export default Modal;
