import React from "react";

import Intro from "../pages/intro";
import QRScan from "../pages/qrscan";
import Notice from "../pages/notice";
import CheckIn from "../pages/checkin";
import Complete from "../pages/complete";
import Guide from "../pages/guide";

const Menu = [
  {
    path: '/', title: '', exact: true, private: false,
    component: () => <Intro />
  },
  {
    path: '/intro', title: '', exact: true, private: false,
    component: () => <Intro />
  },
  {
    path: '/qrscan', title: '', exact: true, private: false,
    component: () => <QRScan />
  },
  {
    path: '/notice', title: '', exact: true, private: false,
    component: () => <Notice />
  },
  {
    path: '/checkin', title: '', exact: true, private: false,
    component: () => <CheckIn />
  },
  {
    path: '/complete', title: '', exact: true, private: true,
    component: () => <Complete />
  },
  {
    path: '/guide', title: '', exact: true, private: true,
    component: () => <Guide />
  },
]

export default Menu;
