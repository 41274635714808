export default {
  app_name: 'チェックイン',
  qr_scan: {
    title: 'QRコードスキャン',
    desc: 'QRコードをスキャン',
  },
  notice: {
    title: '説明書',
    desc: '日本の法律では、\n宿泊者の本人確認と外国人のお客様の場合はパスポ\nート情報を保存することが義務付けられています。',
    check_in: 'チェックイン'
  },
  login: {
    how_many_guests: '宿泊者は何人ですか？',
    passport_photo: 'パスポートを撮る',
    take_passport_photo_desc: '1人のパスポートをカメラで撮影してください',
    enter_guest_info: 'ゲスト情報を入力する',
    enter_guest_info_desc: '1人の宿泊者の情報を入力してください。',
    name: '名前',
    job: '職業',
    address: '住所',
    phone: '携帯電話',
    face_shot: 'フェイスショット',
    face_shot_desc: '1人の顔写真をカメラで撮影します。\n準備ができたら撮影ボタンを押してください。'
  },
  checkin_complete: {
    title: 'チェックイン完了',
    desc: 'チェックイン処理が終了しました。\n心からお過ごしください。'
  },
  guide: {
    title: 'マニュアル',
    check_out: 'チェックアウト'
  },
  popup: {
    enter_guest_info: '{0}名の宿泊者の情報を入力してください。\n日本に住んでいますか?',
    check_out_desc: 'チェックアウトしますか?\nお持ちのものはありませんか?\nお問い合わせをお願いします。',
  },
  alert: {
    enter_guest_count: '宿泊者数を入力してください',
    take_passport_photo: 'パスポートの写真を撮影してください',
    enter_guest_info: '宿泊者情報を入力してください',
    take_face_photo: '顔を撮る',
    face_not_match: 'パスポートの写真と本人の顔は同じではありません'
  },
  common: {
    back: '戻る',
    next: '次',
    cancel: 'キャンセル',
    confirm: '確認',
    no: 'いいえ',
    yes: 'はい',
  }
}