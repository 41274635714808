import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { PageSettings } from '../config/page-settings';
import Container from "../components/control/container";

import '../scss/pages/checkin.scss';
import TopBar from "../components/control/top-bar";
import strings from "../lang/strings";
import CheckInGuest from "./fragment/checkin-guest";
import CheckInPassport from "./fragment/checkin-passport";
import CheckInMainInfo from "./fragment/checkin-main-info";
import CheckInCamera from "./fragment/checkin-camera";
import { inject } from "mobx-react";
import { API_URL } from '../config/const';

const CheckIn = ({ history, rootStore }) => {

  const context = useContext(PageSettings);

  const [step, setStep] = useState(1);
  const [guestList, setGuestList] = useState([]);
  const [inputCount, setInputCount] = useState(1);
  const [guestCount, setGuestCount] = useState(0);

  const baseGuestInfoData = {
    passport: '',
    name: '',
    job: '',
    address: '',
    phone: '',
    photo: '',
  };

  const [guestInfo, setGuestInfo] = useState(baseGuestInfoData);

  // useEffect(() => {
  //   let list = [];
  //   for (let idx = 0; idx < guestCount; idx++) {
  //     list.push(baseGuestInfoData);
  //   }
  //   setGuestList(list);
  // }, [guestCount]);

  const onBack = () => {
    if (step > 1) {
      setStep(prev => prev - 1);
      return;
    }
    history.goBack();
  }

  const canGoNext = () => {
    switch (step) {
      case 1:
        return (guestCount > 0) ? '' : strings.alert.enter_guest_count;
      case 2:
        return guestInfo.passport != '' ? '' : strings.alert.take_passport_photo;
      case 3:
        return (guestInfo.name == '' || guestInfo.job == '' || guestInfo.address == '' || guestInfo.phone == '') ? strings.alert.enter_guest_info : '';
      case 4:
        if (guestInfo.photo == '') {
          return strings.alert.take_face_photo;
        }
        return '';
      default:
        return false;
    }
  }

  const onNext = async () => {
    const msg = canGoNext();
    if (msg != '') {
      context.showAlert(msg);
      return;
    }

    if (step == 4 && guestInfo.passport != '') {
      // 해외 여행자인 경우 여권사진과 얼굴 비교
      const faceCompareResult = await compareFace();
      if (!faceCompareResult) {
        context.showAlert(strings.alert.face_not_match);
        return;
      }
    }

    if (step == 1) {
      context.showConfirm('', strings.formatString(strings.popup.enter_guest_info, 1), strings.common.yes, strings.common.no, (res) => {
        if (res) {  // 일본에 거주
          setStep(3); // 숙박자 정보 입력 화면으로 바로 이동
        } else {  // 외국인
          setStep(2); // 여권 촬영 화면으로 이동
        }
      })
      return;
    }

    if (step >= 4) {
      const newList = guestList;
      newList.push(guestInfo)
      setGuestList(newList);
      setGuestInfo(baseGuestInfoData);
      if (inputCount < guestCount) { // 숙박자수만큼 정보가 입력되지 않은 경우 step2 ~ 4 반복
        context.showConfirm('', strings.formatString(strings.popup.enter_guest_info, 1), strings.common.yes, strings.common.no, (res) => {
          if (res) {  // 일본에 거주
            setStep(3); // 숙박자 정보 입력 화면으로 바로 이동
          } else {  // 외국인
            setStep(2); // 여권 촬영 화면으로 이동
          }
          setInputCount(prev => prev + 1);
        })
        return;        
      }

      // 모든 숙박자 정보가 입력된 경우 체크인 처리진행
      checkin();
      return;
    }
    setStep(prev => prev + 1);
  }

  const compareFace = () => {
    return new Promise(resolve => {
      context.post(
        'room/compare',
        {
          passportUrl: API_URL + '/' + guestInfo.passport,
          faceUrl: API_URL + '/' + guestInfo.photo
        },
        response => {
          resolve(true);
        }
      );
    })
  }

  const checkin = () => {
    console.log(guestList);
    context.post(
      'room/checkin',
      {
        deviceId: rootStore.getUniqueId,
        roomNo: rootStore.getRoomNo,
        guestCount,
        guestInfo: JSON.stringify(guestList)
      },
      response => {
        rootStore.signIn(response.token);
        history.push('/complete');
      }
    );
  }

  return (
    <Container className='checkin'>
      {/* <TopBar
        title={''}
        onBack={onBack}
        rightView={() => (step == 1 || step >= fragments.length) ? null : (
          <button className='btn-next' onClick={onNext}>{strings.signup.next}</button>
        )} /> */}

      <div className='content'>
        {
          step == 1 &&
          <CheckInGuest
            guest={guestCount}
            onChange={(guest) => setGuestCount(guest)}
          />
        }
        {
          step == 2 &&
          <CheckInPassport
            onChange={(file) => {
              const updatedInfo = {
                ...guestInfo,
                passport: file
              }

              setGuestInfo(updatedInfo);
            }}
          />
        }
        {
          step == 3 &&
          <CheckInMainInfo
            guestInfo={guestInfo}
            onChange={(type, value) => {
              const updatedInfo = {
                ...guestInfo,
                [type]: value
              }
              setGuestInfo(updatedInfo)
            }}
          />
        }
        {
          step == 4 &&
          <CheckInCamera
            onChange={(file) => {
              const updatedInfo = {
                ...guestInfo,
                photo: file
              }

              setGuestInfo(updatedInfo);
            }}
          />
        }
      </div>

      <div className='btn-group'>
        <button className='btn btn-next' onClick={onNext}>{strings.common.next}</button>
        <button className='btn btn-back' onClick={onBack}>{strings.common.back}</button>
      </div>
    </Container>
  );
}

export default withRouter(inject('rootStore')(CheckIn));
