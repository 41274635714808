import React, { useEffect } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { Redirect, Switch } from 'react-router';
import { inject, observer } from "mobx-react";
import { AnimatePresence } from "framer-motion";

import strings from "../../lang/strings";
import Menu from './../../config/page-route.jsx';


const Content = ({ history, rootStore }) => {

  const routes = [...Menu];

  const setTitle = path => {
    let pageTitle = strings.app_name;

    /*const route = routes.find(it => it.path === path || it.children?.some(c => c.path === path));
    if (route) {
      if (route.path === path) {
        pageTitle = route.title;
      } else {
        pageTitle = route.children?.find(it => it.path === path)?.title;
      }
    }*/

    document.title = pageTitle;
  }

  const getRoute = (route, index) =>
    <Route
      key={index}
      path={route.path}
      exact={route.exact}
      component={
        !route.private || rootStore.isSignedIn || true ? route.component : () => <Redirect to='/' />
      }
    />;

  useEffect(() => {
    history.listen(() => {
      setTitle(history.location.pathname);
    });

    setTitle(history.location.pathname);
  }, []);

  return (
    <AnimatePresence>
      <Switch>
        {routes.map((route, index) => {
          if (route.hasOwnProperty('children')) {
            return route.children.map((subRoute, subIndex) =>
              getRoute(subRoute, subIndex));
          } else {
            return getRoute(route, index);
          }
        })}
        <Route component={() => <Redirect to='/' />} />
      </Switch>
    </AnimatePresence>
  )
}

export default withRouter(inject('rootStore')(observer(Content)));
