import React from 'react';
import '../../scss/components/top-bar.scss';
import { withRouter } from "react-router-dom";

const TopBar = React.memo(({ history, title, onBack, rightView, center = false, }) => (
  <div className='top-bar'>
    <button className='btn-back' onClick={() => onBack ? onBack() : history.goBack()}>
      <img srcSet={'../../assets/images/icon_back.png 3x'} alt='back' />
    </button>
    <label className={'page-title' + (center ? ' center' : '')}>{title}</label>
    {rightView ? rightView() : null}
  </div>
));

export default withRouter(TopBar);
