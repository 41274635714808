// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qrscan {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  background: #ffffff;
  padding: 0 20px;
}
.qrscan .title {
  color: #151515;
  font-size: 28px;
  font-weight: 900;
  margin-top: 44px;
  margin-bottom: 8px;
}
.qrscan .desc {
  color: #151515;
  opacity: 0.45;
  font-size: 14px;
  line-height: 24px;
}
.qrscan .scanner {
  width: 100%;
  height: 375px;
  border-radius: 10px;
  margin-top: auto;
  margin-bottom: auto;
}
.qrscan .btn {
  width: 100%;
  height: 48px;
  border-radius: 16px;
  font-size: 16px;
  margin-bottom: 20px;
  background-color: #167351;
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/scss/pages/qrscan.scss","webpack://./src/scss/variables/coreVars.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,mBCJM;EDKN,eAAA;AADF;AAGE;EACE,cCTI;EDUJ,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;AADJ;AAIE;EACE,cCjBI;EDkBJ,aAAA;EACA,eAAA;EACA,iBAAA;AAFJ;AAKE;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;AAHJ;AAME;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,eAAA;EACA,mBAAA;EACA,yBCnCM;EDoCN,cCrCI;ADiCR","sourcesContent":["@import \"../variables/coreVars.scss\";\r\n\r\n.qrscan {\r\n  display: flex;\r\n  flex-direction: column;\r\n  width: 100%;\r\n  height: 100vh;\r\n  align-items: center;\r\n  background: $white;\r\n  padding: 0 20px;\r\n\r\n  .title {\r\n    color: $black;\r\n    font-size: 28px;\r\n    font-weight: 900;\r\n    margin-top: 44px;\r\n    margin-bottom: 8px;\r\n  }\r\n\r\n  .desc {\r\n    color: $black;\r\n    opacity: 0.45;\r\n    font-size: 14px;\r\n    line-height: 24px;\r\n  }\r\n\r\n  .scanner {\r\n    width: 100%;\r\n    height: 375px;\r\n    border-radius: 10px;\r\n    margin-top: auto;\r\n    margin-bottom: auto;\r\n  }\r\n\r\n  .btn {\r\n    width: 100%;\r\n    height: 48px;\r\n    border-radius: 16px;\r\n    font-size: 16px;\r\n    margin-bottom: 20px;\r\n    background-color: $primary;\r\n    color: $white;\r\n  }\r\n}\r\n","// Storing Core Variables for styling\r\n\r\n// Color\r\n$black: #151515;\r\n$white: #ffffff;\r\n$primary: #167351;\r\n\r\n// Dimension\r\n$topBarHeight: 54px;\r\n$topBarColor: $black;\r\n$topBarFontSize: 17px;\r\n$topBarFontWeight: bold;\r\n$gnbHeight: 70px;\r\n$windowWidthForMedia: 400px;\r\n$windowHeightForMedia: 100vh;\r\n\r\n// Animation\r\n$animationTime: .2s;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
