module.exports = {
  isLocalhost: true,
  API_URL: 'https://api.hairo.life', // this.isLocalhost ? 'http://192.168.0.54:3005' : 'https://api.hairo.life',

  IS_UI_MODE: false,
  IS_OFFLINE_MODE: true,

  HEADER: {
    AUTH_TOKEN: 'x-access-token',
    CONTENT_TYPE: {
      NAME: 'Content-Type',
      JSON: 'application/json',
      FILE: 'multipart/form-data',
      FORM: 'application/x-www-form-urlencoded'
    },
    LANG: 'Accept-Language',
  },

  //. 각종 시간/일자 포맷
  FORMAT: {
    DATE_TIME: 'YYYY-MM-DD HH:mm:ss',
    DATE: 'YYYY-MM-DD',
    TIME: 'HH:mm:ss',
    MONTH: 'YYYY-MM',
    YEAR: 'YYYY'
  },

  //. 응답코드
  RESULT_CODE: {
    SUCCESS: 0,                     //. 정상
    EMPTY_TOKEN: -1,                //. 토큰정보 없음.
    TOKEN_EXPIRED: -2,              //. 토큰 기한 만료
    EMPTY_PARAM: -3,                //. 요청파라미터 누락
    FACE_NOT_MATCH: -4,             //. 얼굴비교 오류
    ERROR: -10,                     //. api 내부 오류
  },

  //. 언어
  LANG_TYPE: {
    JA: 'ja',
    EN: 'en',
    ZH_CN: 'zh_cn',
    ZH_TW: 'zh_tw',
    KO: 'ko'
  },
}
