import React from 'react';
import { withRouter } from "react-router-dom";
import axios from "axios";
import { rootStore } from "./mobx/store";
import { inject, observer } from "mobx-react";

import { PageSettings } from './config/page-settings.js';
import Content from './components/content/content.jsx';
import LoadingBar from "./components/control/loading-bar";
import Modal from "./components/control/modal";
import { API_URL, HEADER, RESULT_CODE } from "./config/const";
import strings from "./lang/strings";
import generateString from './helper/common.js';


class App extends React.Component {
  constructor(props) {
    super(props);

    this.toggleMobileSidebar = () => {
      this.setState(state => ({
        pageSidebarToggled: !state.pageSidebarToggled
      }));
    }
    this.handleSetPageSidebar = (value) => {
      this.setState({
        pageSidebar: value
      });
    }
    this.handleSetPageHeader = (value) => {
      this.setState({
        pageHeader: value
      });
    };
    this.handleSetPageBoxedLayout = (value) => {
      if (value === true) {
        document.body.classList.add('boxed-layout');
      } else {
        document.body.classList.remove('boxed-layout');
      }
    }
    this.toggleLoadingBar = isLoading => {
      this.setState({ pageLoading: isLoading });
    }
    this.showGallery = (image_list, index = 0) => {
      this.setState({
        galleryShow: true,
        galleryData: image_list,
        galleryIndex: index,
      });
    }
    this.showModal = (title, text, okBtn, cancelBtn, cb) => {
      this.setState({
        modal: {
          show: true,
          title: title,
          text: text,
          subText: '',
          okBtn: okBtn,
          cancelBtn: cancelBtn,
          cb: cb
        }
      });
    }
    this.showAlert = (text, cb, okBtn = strings.common.confirm) => {
      this.showModal('', text, okBtn, '', cb);
    }
    this.showConfirm = (title, text, okBtn, cancelBtn, cb) => {
      this.showModal(title, text, okBtn, cancelBtn, cb);
    }
    this.api = axios.create({
      baseURL: API_URL + '/app/',
      headers: {
        [HEADER.LANG]: 'ko'
      }
    })
    this.post = async (uri, params, cbSuccess, cbFail, showProgress = true) => {
      await this.request('POST', uri, params, cbSuccess, cbFail, showProgress);
    }
    this.get = async (uri, params, cbSuccess, cbFail, showProgress = true) => {
      await this.request('GET', uri, params, cbSuccess, cbFail, showProgress);
    }
    this.delete = async (uri, params, cbSuccess, cbFail, showProgress = true) => {
      await this.request('DELETE', uri, params, cbSuccess, cbFail, showProgress);
    }
    this.request = async (method, uri, params, cbSuccess, cbFail, showProgress = true) => {
      if (showProgress) {
        this.toggleLoadingBar(true);
      }

      this.api.defaults.headers.common[HEADER.AUTH_TOKEN] = this.props.rootStore.getToken;

      try {
        let { data } = method !== 'GET' ?
          await this.api[method.toLowerCase()](uri, params)
          :
          await this.api.get(uri, { params });

        if (showProgress) {
          this.toggleLoadingBar(false);
        }

        if (Number(data.result_code) === RESULT_CODE.SUCCESS) {
          cbSuccess?.(data.result_data);
        } else {
          if (cbFail) {
            cbFail?.(data);
          } else {
            this.handleApiError(data);
          }
        }
      } catch (err) {
        console.log(err);

        if (showProgress) {
          this.toggleLoadingBar(false);
        }

        this.showAlert(err.message);
      }
    }

    this.download = async (method, uri, params, cb, showProgress = true) => {
      if (showProgress) {
        this.toggleLoadingBar(true);
      }

      this.api.defaults.headers.common[HEADER.AUTH_TOKEN] = this.props.rootStore.token;
      this.api.defaults.responseType = 'blob';


      try {
        let response = method !== 'GET' ?
          await this.api[method.toLowerCase()](uri, params)
          :
          await this.api.get(uri, { params });

        if (showProgress) {
          this.toggleLoadingBar(false);
        }


        const filename = decodeURIComponent(response.headers["x-suggested-filename"]);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();

      } catch (err) {
        console.log(err);

        if (showProgress) {
          this.toggleLoadingBar(false);
        }

        this.showAlert(err.message);
      }
    }

    this.handleApiError = e => {
      if (Number(e.result_code) === RESULT_CODE.EMPTY_TOKEN ||
        Number(e.result_code) === RESULT_CODE.TOKEN_EXPIRED) {
        this.props.history.replace('/login');
        return;
      }

      this.showAlert(e.result_msg);
    }

    this.state = {
      hasScroll: false,

      pageHeader: true,
      handleSetPageHeader: this.handleSetPageHeader,

      pageSidebar: true,
      pageSidebarToggled: false,
      handleSetPageSidebar: this.handleSetPageSidebar,
      toggleMobileSidebar: this.toggleMobileSidebar,

      handleSetPageBoxedLayout: this.handleSetPageBoxedLayout,

      pageLoading: false,
      toggleLoadingBar: this.toggleLoadingBar,

      modal: {
        show: false,
        title: '',
        text: '',
        subText: '',
        okBtn: '',
        cancelBtn: '',
        cb: null
      },
      showModal: this.showModal,
      showAlert: this.showAlert,
      showConfirm: this.showConfirm,

      showGallery: this.showGallery,
      galleryShow: false,
      galleryData: [],
      galleryIndex: 0,

      post: this.post,
      get: this.get,
      delete: this.delete,
      download: this.download,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    const uniqueId = this.props.rootStore.getUniqueId;
    if (uniqueId == null || uniqueId == '') {
      const randomString = generateString(15);
      console.log('Generated Unique Id >>>>>>>', randomString);
      this.props.rootStore.setUniqueId(randomString);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    this.setState({
      hasScroll: window.scrollY > 0
    });
    let elm = document.getElementsByClassName('nvtooltip');
    for (let i = 0; i < elm.length; i++) {
      elm[i].classList.add('d-none');
    }
  }

  onModalClose = res => {
    this.setState(state => ({
      modal: { ...state.modal, show: false }
    }), () => this.state.modal.cb?.(res));
  }

  render() {
    return (
      <PageSettings.Provider value={this.state}>
        <>
          <Content />
          <Modal {...this.state.modal} onModalClose={this.onModalClose} />
          {this.state.pageLoading && <LoadingBar />}
        </>
      </PageSettings.Provider>
    )
  }
}

export default withRouter(inject('rootStore')(observer(App)));
