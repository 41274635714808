import React, { useCallback, useContext, useRef } from 'react';

import strings from "../../lang/strings";
import Webcam from 'react-webcam';
import { PageSettings } from '../../config/page-settings';

const CheckInPassport = (props) => {

  const context = useContext(PageSettings);

  const { onChange } = props;

  const webcamRef = useRef(null);
  const capture = useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      fileUpload(imageSrc)
    },
    [webcamRef]
  );

  const videoConstraints = {
    // width: 300,
    // height: 280,
    facingMode: { exact: "environment" }
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }

  const fileUpload = (imageData) => {
    const file = dataURLtoFile(imageData, 'passport.png');
    // 여권사진 업로드
    let formData = new FormData();
    formData.append('file', file, file.name);

    context.post(
      'upload/image/checkin',
      formData,
      response => {
        onChange(response.image);
      }
    );
  }

  return (
    <div className='checkin-passport'>
      <div className='title'>{strings.login.passport_photo}</div>
      <label className='desc'>{strings.login.take_passport_photo_desc}</label>
      <div className='camera-view'>
        <Webcam
          audio={false}
          // height={280}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width={'100%'}
          videoConstraints={videoConstraints}
        />
      </div>
      <button className='btn-camera' onClick={capture}>
        <img src='assets/images/camera.png' />
      </button>
    </div>
  );
}

export default CheckInPassport;
