import { inject } from 'mobx-react';
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { PageSettings } from '../config/page-settings';
import Container from "../components/control/container";

import '../scss/pages/complete.scss';
import strings from "../lang/strings";

const Complete = ({ rootStore, history }) => {

  const context = useContext(PageSettings);

  const onConfirm = () => {
    history.push('/guide');
  }

  return (
    <Container className='complete'>

      <div className='content'>

        <div className='title'>{strings.checkin_complete.title}</div>
        <div className='logo-img'>
          <img src='assets/images/confirm.png' />
        </div>
        <div className='desc'>{strings.checkin_complete.desc}</div>

        <button className='btn btn-confirm' onClick={onConfirm}>{strings.common.confirm}</button>
      </div>
    </Container>
  );
}

export default withRouter(inject('rootStore')(Complete));
