import { inject } from 'mobx-react';
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { PageSettings } from '../config/page-settings';
import Container from "../components/control/container";

import '../scss/pages/notice.scss';
import strings from "../lang/strings";
import { storage } from "../mobx/store";

const Notice = ({ rootStore, history }) => {

  const context = useContext(PageSettings);

  const onCheckIn = () => {
    history.push('/checkin');
  }

  return (
    <Container className='notice'>

      <div className='content'>

        <div className='title'>{strings.notice.title}</div>
        <div className='logo-img'>
          <img src='assets/images/success.png' />
        </div>
        <div className='desc'>{strings.notice.desc}</div>

        <button className='btn btn-checkin' onClick={onCheckIn}>{strings.notice.check_in}</button>
        <button className='btn btn-back' onClick={() => history.goBack()}>{strings.common.back}</button>
      </div>
    </Container>
  );
}

export default withRouter(inject('rootStore')(Notice));
