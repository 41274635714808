// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.guide {
  width: 100%;
  height: 100vh;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
}
.guide .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1;
}
.guide .content .title {
  color: #151515;
  font-size: 28px;
  font-weight: 900;
  margin-top: 44px;
}
.guide .content .text {
  color: #151515;
  font-size: 14px;
  line-height: 24px;
  margin-top: 30px;
  white-space: pre-wrap;
}
.guide .btn {
  width: 100%;
  height: 48px;
  border-radius: 16px;
  font-size: 16px;
}
.guide .btn.btn-confirm {
  background-color: #167351;
  color: #ffffff;
  margin-top: auto;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/scss/pages/guide.scss","webpack://./src/scss/variables/coreVars.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,aAAA;EACA,eAAA;EACA,aAAA;EACA,sBAAA;AADF;AAGE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;AADJ;AAGI;EACE,cCbE;EDcF,eAAA;EACA,gBAAA;EACA,gBAAA;AADN;AAII;EACE,cCpBE;EDqBF,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,qBAAA;AAFN;AAME;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,eAAA;AAJJ;AAMI;EACE,yBCjCI;EDkCJ,cCnCE;EDoCF,gBAAA;EACA,mBAAA;AAJN","sourcesContent":["@import \"../variables/coreVars.scss\";\r\n\r\n.guide {\r\n  width: 100%;\r\n  height: 100vh;\r\n  padding: 0 20px;\r\n  display: flex;\r\n  flex-direction: column;\r\n\r\n  .content {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    flex: 1;\r\n\r\n    .title {\r\n      color: $black;\r\n      font-size: 28px;\r\n      font-weight: 900;\r\n      margin-top: 44px;\r\n    }\r\n\r\n    .text {\r\n      color: $black;\r\n      font-size: 14px;\r\n      line-height: 24px;\r\n      margin-top: 30px;\r\n      white-space: pre-wrap;\r\n    }\r\n  }\r\n\r\n  .btn {\r\n    width: 100%;\r\n    height: 48px;\r\n    border-radius: 16px;\r\n    font-size: 16px;\r\n\r\n    &.btn-confirm {\r\n      background-color: $primary;\r\n      color: $white;\r\n      margin-top: auto;\r\n      margin-bottom: 20px;\r\n    }\r\n  }\r\n}","// Storing Core Variables for styling\r\n\r\n// Color\r\n$black: #151515;\r\n$white: #ffffff;\r\n$primary: #167351;\r\n\r\n// Dimension\r\n$topBarHeight: 54px;\r\n$topBarColor: $black;\r\n$topBarFontSize: 17px;\r\n$topBarFontWeight: bold;\r\n$gnbHeight: 70px;\r\n$windowWidthForMedia: 400px;\r\n$windowHeightForMedia: 100vh;\r\n\r\n// Animation\r\n$animationTime: .2s;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
