import React from 'react';
import { motion } from "framer-motion";

const Container = ({ id = '', className = '', children }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.23 }}
      exit={{ opacity: 0 }}
      id={id}
      className={className}>
      {children}
    </motion.div>
  );
}

export default Container;
