import React from 'react';
import ReactLoading from 'react-loading';

const LoadingBar = React.memo(() => (
  <div style={{
    display: 'flex',
    width: '100%',
    height: '100%',
    position: 'fixed',
    left: '0',
    top: '0',
    backgroundColor: 'rgba(0,0,0,0.2)',
    zIndex: '30000',
    alignItems: 'center',
    justifyContent: 'center'
  }}>
    <ReactLoading type='spinningBubbles' color='#000000' className='loading' />
  </div>
));

export default LoadingBar;
