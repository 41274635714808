export default {
  app_name: '报到',
  qr_scan: {
    title: '扫描二维码',
    desc: '扫描二维码',
  },
  notice: {
    title: '指示',
    desc: '日本法律要求客人验证身份,\n如果是外国客人\n则需要存储护照信息。',
    check_in: '报到'
  },
  login: {
    how_many_guests: '有多少位客人？',
    passport_photo: '拍摄护照照片',
    take_passport_photo_desc: '请用相机拍摄一个人的护照照片。',
    enter_guest_info: '输入客人信息',
    enter_guest_info_desc: '请输入一位客人的信息。',
    name: '姓名',
    job: '工作',
    address: '地址',
    phone: '电话号码',
    face_shot: '脸部射击',
    face_shot_desc: '用相机拍摄一个人的脸部照片。\n准备好后, 按下拍摄按钮。'
  },
  checkin_complete: {
    title: '值机完毕',
    desc: '值机处理已结束。\n我希望你感到安心。'
  },
  guide: {
    title: '手动的',
    check_out: '查看'
  },
  popup: {
    enter_guest_info: '请输入{0}位客人的信息。你住在日本吗?',
    check_out_desc: '您想查看吗?\n你有留下什么东西吗?\n请关门。',
  },
  alert: {
    enter_guest_count: '请输入入住人数',
    take_passport_photo: '拍一张护照照片',
    enter_guest_info: '请输入客人信息',
    take_face_photo: '拍一张你的脸的照片',
    face_not_match: '您的护照照片和您的脸不一样'
  },
  common: {
    back: '向后',
    next: '下一个',
    cancel: '消除',
    confirm: '查看',
    no: '不',
    yes: '是的',
  }
}