export default {
  app_name: 'Check-in',
  qr_scan: {
    title: 'Scan QR code',
    desc: 'Scan the QR code',
  },
  notice: {
    title: 'Instructions',
    desc: 'Japanese law requires\nguests to verify their identity and, in the case of\nforeign guests, to store passport information.',
    check_in: 'Check-in'
  },
  login: {
    how_many_guests: 'How many guests are there?',
    passport_photo: 'Take a passport photo',
    take_passport_photo_desc: 'Please take a photo of one person\'s passport with a camera.',
    enter_guest_info: 'Enter guest info',
    enter_guest_info_desc: 'Please enter information for one guest.',
    name: 'Name',
    job: 'Job',
    address: 'Address',
    phone: 'Phone',
    face_shot: 'Face shot',
    face_shot_desc: 'Take a photo of one person\'s face with the camera.\nWhen you are ready, press the shooting button.'
  },
  checkin_complete: {
    title: 'Check-in Complete',
    desc: 'Check-in processing has ended.\nI hope you feel at ease.'
  },
  guide: {
    title: 'Manual',
    check_out: 'Check out'
  },
  popup: {
    enter_guest_info: 'Please enter the information of {0} guests.\nDo you live in Japan?',
    check_out_desc: 'Would you like to check out?\nDid you leave anything behind?\nPlease close the door.',
  },
  alert: {
    enter_guest_count: 'Please enter the number of guests.',
    take_passport_photo: 'Take a passport photo.',
    enter_guest_info: 'Please enter guest information.',
    take_face_photo: 'Take a photo of your face.',
    face_not_match: 'Your passport photo and your face are not the same.'
  },
  common: {
    back: 'Back',
    next: 'Next',
    cancel: 'Cancel',
    confirm: 'Confirm',
    no: 'No',
    yes: 'Yes',
  }
}