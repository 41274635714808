import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import Container from "../components/control/container";
import strings from "../lang/strings";

import '../scss/pages/intro.scss';
import { inject } from "mobx-react";
import { LANG_TYPE } from '../config/const';
import { PageSettings } from '../config/page-settings';


const Intro = ({ history, rootStore }) => {

  const context = useContext(PageSettings);

  useEffect(() => {

  }, []);

  const onLang = (lang) => {
    strings.setLanguage(lang);
    history.push('/qrscan');
  }

  const onTest = () => {
    let data = {
      "sourceImage": {
        "url": "https://api.hairo.life/uploads/image/checkin/2024/5/9/image_1715219512542.png"
      },
      "targetImage": {
        "url": "https://api.hairo.life/uploads/image/checkin/2024/5/9/image_1715219776439.png"
      },
      "threshold": 90
    };

    context.post(
      'room/compare',
      {
        
        passportUrl: "https://api.hairo.life/uploads/image/checkin/2024/5/9/image_1715219512542.png",
        faceUrl: "https://api.hairo.life/uploads/image/checkin/2024/5/9/image_1715219776439.png"
      },
      response => {
        console.log(response);
      }
    );
  }

  return (
    <Container className='intro'>
      <div className='bg'>
        <img src='assets/images/bg.png' />
      </div>

      <div className='desc'>
        <div className='welcome'>{'Welcome'}</div>
        <div>{'言語を選んでください'}</div>
        <div>{'Choose your language.'}</div>
        <div>{'选择你的语言'}</div>
        <div>{'選擇你的語言'}</div>
        <div>{'언어를 선택하십시오.'}</div>
      </div>

      <button className='btn btn-lang' onClick={() => onLang(LANG_TYPE.JA)}>
        {'日本語'}
      </button>
      <button className='btn btn-lang' onClick={() => onLang(LANG_TYPE.EN)}>
        {'English'}
      </button>
      <button className='btn btn-lang' onClick={() => onLang(LANG_TYPE.ZH_CN)}>
        {'中文(简体)'}
      </button>
      <button className='btn btn-lang' onClick={() => onLang(LANG_TYPE.ZH_TW)}>
        {'中文(繁体)'}
      </button>
      <button className='btn btn-lang' onClick={() => onLang(LANG_TYPE.KO)}>
        {'한국'}
      </button>
      <div className='bottom'>{'Check-in'}</div>
    </Container>
  );
}

export default withRouter(inject('rootStore')(Intro));
