import { action, observable } from 'mobx';

export const storage = {
  getCheckInInfo: () => {
    const info = sessionStorage.getItem('checkin_info');
    return info ? JSON.parse(info) : {};
  },
  saveCheckInInfo: info => {
    sessionStorage.setItem('checkin_info', JSON.stringify(info));
  },
  removeCheckInInfo: () => {
    sessionStorage.removeItem('checkin_info');
  },
  setAutoLogin: (type, id, pwd = '') => {
    localStorage.setItem('auto_login', JSON.stringify({
      type, id, pwd
    }));
  },
  getAutoLogin: () => {
    const info = localStorage.getItem('auto_login');
    if (info == null) {
      return null;
    } else {
      return JSON.parse(info);
    }
  },
  removeAutoLogin: () => {
    localStorage.removeItem('auto_login');
  },
};

export const rootStore = observable({

  token: null,
  me: null,
  uniqueId: null,
  roomNo: null,

  signIn: token => {
    rootStore.token = token;
    sessionStorage.setItem('token', token);
  },

  setMyInfo: user => {
    rootStore.me = user;
    sessionStorage.setItem('user', JSON.stringify(user));
  },

  signOut: () => {
    rootStore.token = null;
    rootStore.me = null;
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
  },

  setUniqueId: id => {
    rootStore.uniqueId = id;
    localStorage.setItem('uniqueId', id);
  },
  
  setRoomNo: roomNo => {
    rootStore.roomNo = roomNo;
    sessionStorage.setItem('roomNo', roomNo);
  },

  get getMe() {
    if (rootStore.me == null) {
      try {
        rootStore.me = JSON.parse(sessionStorage.getItem('user'));
      } catch (e) {
        console.log(e);
      }
    }
    return rootStore.me;
  },

  get getToken() {
    if (rootStore.token == null) {
      rootStore.token = sessionStorage.getItem('token');
    }
    return rootStore.token;
  },

  get getUniqueId() {
    if (rootStore.uniqueId == null) {
      rootStore.uniqueId = localStorage.getItem('uniqueId');
    }
    return rootStore.uniqueId;
  },
  
  get getRoomNo() {
    if (rootStore.roomNo == null) {
      rootStore.roomNo = sessionStorage.getItem('roomNo');
    }
    return rootStore.roomNo;
  },
}, {
  signIn: action,
  signOut: action,
  setMyInfo: action,
  setUniqueId: action,
  setRoomNo: action
});
