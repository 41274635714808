import ko from "./locales/ko";
import en from "./locales/en";
import ja from "./locales/ja";
import zh_cn from "./locales/zh_CN";
import zh_tw from "./locales/zh_TW";
import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({
  en, ko, ja, zh_cn, zh_tw
});
strings.setLanguage('ko');

export default strings;
