import React from 'react';

import strings from "../../lang/strings";

const CheckInGuest = (props) => {

  const { guest, onChange } = props;

  return (
    <div className='checkin-guest'>
      <div className='image'>
        <img src='assets/images/users.png' />
      </div>
      <div className='text'>{strings.login.how_many_guests}</div>
      <div className='guest-input'>
        <input type='number' placeholder='0' value={guest} onChange={e => onChange(e.target.value)} />
      </div>
    </div>
  );
}

export default CheckInGuest;
