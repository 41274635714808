import { API_URL, FORMAT } from "../config/const";
import React from "react";

const dayjs = require('dayjs');

Array.prototype.equal = function (array) {
  const a = JSON.parse(JSON.stringify(this)).sort();
  const b = JSON.parse(JSON.stringify(array)).sort();

  return a.join(',') == b.join(',');
};

String.prototype.currencyFormat = function () {
  return this.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

String.prototype.dateFormat = function (format = FORMAT.DATE_TIME) {
  return dayjs(this).isValid() ? dayjs(this).format(format) : '';
};

// for reactjs
String.prototype.nl2br = function () {
  return this.split('\n').map((line, i) => (
    <p key={i} className='mb-0'>{line}</p>));
};

Number.prototype.currencyFormat = function () {
  return this.toString().currencyFormat();
};

String.prototype.getThumbnailUrl = function () {
  // TODO: uncomment
  // const ext = this.substr(this.lastIndexOf('.'));
  // return this.replace(ext, `_thumb${ext}`);
  return this;
};

String.prototype.getFullUrl = function () {
  if (this === '') {
    return '';
  }

  return (this.startsWith('http') || this.startsWith('data')) ? this : `${API_URL + '/' + this}`;
}

String.prototype.getImages = function (onlyFirstImage = true) {
  if (this == null || this == '') {
    return onlyFirstImage ? '' : [];
  }

  const images = this.split(',').filter(it => it != '').map(it => it.getFullUrl());
  return onlyFirstImage ? (images.length > 0 ? images[0] : '') : images;
};
