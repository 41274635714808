export default {
  app_name: '報到',
  qr_scan: {
    title: '掃描二維碼',
    desc: '掃描二維碼',
  },
  notice: {
    title: '通知',
    desc: '日本法律要求客人驗證身份\n如果是外國客人\n則需要儲存護照資訊。',
    check_in: '報到'
  },
  login: {
    how_many_guests: '有多少位客人？',
    passport_photo: '護照照片',
    take_passport_photo_desc: '請用相機拍攝一個人的護照照片。',
    enter_guest_info: '輸入客人資訊',
    enter_guest_info_desc: '請輸入一位客人的資料。',
    name: '姓名',
    job: '工作',
    address: '地址',
    phone: '電話號碼',
    face_shot: '臉部射擊',
    face_shot_desc: '用相機拍攝一個人的臉部照片。\n準備好後,按下拍攝按鈕。'
  },
  checkin_complete: {
    title: '報到完畢',
    desc: '報到處理已結束。\n我希望你感到安心。'
  },
  guide: {
    title: '手動的',
    check_out: '查看'
  },
  popup: {
    enter_guest_info: '請輸入{0}位客人的資料。\n你住在日本嗎?',
    check_out_desc: '您想查看嗎?\n你留下什麼東西嗎?\n請關門。',
  },
  alert: {
    enter_guest_count: '請輸入入住人數',
    take_passport_photo: '拍一張護照照片',
    enter_guest_info: '請輸入客人資料',
    take_face_photo: '拍一張你的臉的照片',
    face_not_match: '您的護照照片和您的臉不一樣'
  },
  common: {
    back: '向後',
    next: '下一個',
    cancel: '消除',
    confirm: '查看',
    no: '不',
    yes: '是的',
  }
}