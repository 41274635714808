import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { PageSettings } from '../config/page-settings';
import Container from "../components/control/container";
import strings from "../lang/strings";

import '../scss/pages/guide.scss';
import { rootStore } from '../mobx/store';


const Guide = ({ history, type }) => {

  const context = useContext(PageSettings);

  const [roomInfo, setRoomInfo] = useState(null);

  useEffect(() => {
    context.get(
      'room/info',
      {},
      response => {
        // {
        //   "idx": 1,
        //   "roomNo": "1",
        //   "wifi": "182812391239",
        //   "guide": "asdfasdfasdfasdf",
        //   "other": null,
        //   "createDt": "2024-05-01 22:46:55"
        // }
        setRoomInfo(response);
      }
    );
  }, []);

  const onCheckOut = () => {
    context.showConfirm('', strings.popup.check_out_desc, strings.common.confirm, strings.common.cancel, (res) => {
      if (res == true) {
        context.post(
          'room/checkout',
          {
            deviceId: rootStore.getUniqueId,
            roomNo: roomInfo.roomNo
          },
          response => {
            rootStore.signOut();
            history.push('/')
          }
        );
      }
    })
  }

  return (
    <Container className='guide'>

      <div className='content'>
        <div className='title'>{strings.guide.title}</div>
        <div className='text'>
          {`WIFI: ${roomInfo?.wifi || ''}\n\n리모콘 사용법: ${roomInfo?.guide || ''}\n\n체크인: ${roomInfo?.createDt || ''}`}
        </div>
      </div>

      <button className='btn btn-confirm' onClick={onCheckOut}>{strings.guide.check_out}</button>
    </Container>
  );
}

export default withRouter(Guide);
