// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-bar {
  position: fixed;
  display: flex;
  width: 100%;
  height: 54px;
  top: 0;
  left: 0;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  z-index: 2;
}
.top-bar .btn-back {
  width: 54px;
  height: 54px;
}
.top-bar .page-title {
  color: #151515;
  font-size: 17px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 60%;
  overflow: hidden;
}
.top-bar .page-title.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/scss/components/top-bar.scss","webpack://./src/scss/variables/coreVars.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,aAAA;EACA,WAAA;EACA,YCEa;EDDb,MAAA;EACA,OAAA;EACA,mBAAA;EACA,mBAAA;EACA,yBCPM;EDQN,UAAA;AADF;AAGE;EACE,WCPW;EDQX,YCRW;ADOf;AAIE;EACE,cCjBI;EDkBJ,eCXa;EDYb,iBCXe;EDYf,mBAAA;EACA,uBAAA;EACA,cAAA;EACA,gBAAA;AAFJ;AAII;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,gCAAA;AAFN","sourcesContent":["@import \"../variables/coreVars.scss\";\r\n\r\n.top-bar {\r\n  position: fixed;\r\n  display: flex;\r\n  width: 100%;\r\n  height: $topBarHeight;\r\n  top: 0;\r\n  left: 0;\r\n  flex-direction: row;\r\n  align-items: center;\r\n  background-color: $white;\r\n  z-index: 2;\r\n\r\n  .btn-back {\r\n    width: $topBarHeight;\r\n    height: $topBarHeight;\r\n  }\r\n\r\n  .page-title {\r\n    color: $topBarColor;\r\n    font-size: $topBarFontSize;\r\n    font-weight: $topBarFontWeight;\r\n    white-space: nowrap;\r\n    text-overflow: ellipsis;\r\n    max-width: 60%;\r\n    overflow: hidden;\r\n\r\n    &.center {\r\n      position: absolute;\r\n      left: 50%;\r\n      top: 50%;\r\n      transform: translate(-50%, -50%);\r\n    }\r\n  }\r\n}\r\n","// Storing Core Variables for styling\r\n\r\n// Color\r\n$black: #151515;\r\n$white: #ffffff;\r\n$primary: #167351;\r\n\r\n// Dimension\r\n$topBarHeight: 54px;\r\n$topBarColor: $black;\r\n$topBarFontSize: 17px;\r\n$topBarFontWeight: bold;\r\n$gnbHeight: 70px;\r\n$windowWidthForMedia: 400px;\r\n$windowHeightForMedia: 100vh;\r\n\r\n// Animation\r\n$animationTime: .2s;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
