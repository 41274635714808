import React from 'react';

import '../../scss/pages/checkin.scss';
import strings from "../../lang/strings";

const CheckInMainInfo = (props) => {

  const { guestInfo, onChange } = props;

  return (
    <div className='main-info'>
      <div className='title'>{strings.login.enter_guest_info}</div>
      <label className='desc'>{strings.login.enter_guest_info_desc}</label>
      {/*Name*/}
      <div className='input-wrapper' style={{ marginTop: 'auto' }}>
        <input type='text' value={guestInfo?.name || ''} placeholder={strings.login.name}
          onChange={e => onChange('name', e.target.value)} />
      </div>
      {/*Job*/}
      <div className='input-wrapper'>
        <input type='text' value={guestInfo?.job || ''} placeholder={strings.login.job}
          onChange={e => onChange('job', e.target.value)} />
      </div>
      {/*Address*/}
      <div className='input-wrapper'>
        <input type='text' value={guestInfo?.address || ''} placeholder={strings.login.address}
          onChange={e => onChange('address', e.target.value)} />
      </div>
      {/*Phone*/}
      <div className='input-wrapper' style={{ marginBottom: 'auto' }}>
        <input type='tel' value={guestInfo?.phone || ''} placeholder={strings.login.phone}
          onChange={e => onChange('phone', e.target.value)} />
      </div>
    </div>
  );
}

export default CheckInMainInfo;
