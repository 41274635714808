import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { PageSettings } from '../config/page-settings';
import Container from "../components/control/container";

import '../scss/pages/qrscan.scss';
import { inject } from "mobx-react";
import strings from '../lang/strings';
import { Scanner } from '@yudiel/react-qr-scanner';
import jwtDecode from 'jwt-decode';


const QRScan = ({ history, rootStore }) => {
  const context = useContext(PageSettings);

  useEffect(() => {

  }, []);

  const checkState = (scannedText) => {
    // const jwt = checkJwt(scannedText);
    if (!checkJwt(scannedText)) {
      return;
    }

    try {
      const qrInfo = jwtDecode(scannedText);
      if (!qrInfo.room) {
        return;
      }
      const roomNo = qrInfo.room;
      rootStore.setRoomNo(roomNo);
      const uniqueId = rootStore.getUniqueId;
      if (uniqueId == null || uniqueId == '') {
        return;
      }

      context.post(
        'room/check',
        {
          id: uniqueId,
          room: roomNo
        },
        response => {
          // 체크인 한 경우
          rootStore.signIn(response.token);
          history.push('/guide');
        },
        () => {
          // 체크인 하지 않은 경우
          history.push('/notice');
        }
      );
    } catch (e) {
      console.log(e);
      return;
    }
  }

  const checkJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    try {
      var jwt = window.atob(base64);
    } catch (e) {
      return false;
    }

    return true;
  }

  return (
    <Container className='qrscan'>
      <div className='title'>{strings.qr_scan.title}</div>
      <div className='desc'>{strings.qr_scan.desc}</div>
      <div className='scanner'>
        <Scanner
          options={{
            constraints: {
              facingMode: 'environment'
            }
          }}
          onResult={(text, result) => {
            console.log(text, result);
            checkState(result.getText())
          }}
          onError={(error) => {
            console.log(error?.message)
            history.push('/notice');
          }}
        />
      </div>
      <button className='btn' onClick={() => history.goBack()}>{strings.common.back}</button>
    </Container>
  );
}

export default withRouter(inject('rootStore')(QRScan));
